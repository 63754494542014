import {is_web} from "../helpers";
import {ActivityIndicator, TouchableOpacity} from "react-native";
import {Alert, Center, HStack, Icon, Text, useToast} from "native-base";
import {FontAwesome} from "@expo/vector-icons";
import React, {useState} from "react";
import * as FileSystem from "expo-file-system";
import * as MediaLibrary from "expo-media-library";
import {Day} from "../MainPage";

export function PictureOfTheDayCard({today_data}: { today_data: Day }) {
	const toast = useToast();
	const [is_loading, set_is_loading] = useState(false);

	function download_image_for_web(url, name) {
		fetch(url)
			.then(resp => resp.blob())
			.then(blob => {
				const url = window.URL.createObjectURL(blob);
				const a = document.createElement("a");
				a.style.display = "none";
				a.href = url;
				a.download = name;
				document.body.appendChild(a);
				a.click();
				window.URL.revokeObjectURL(url);
			})
			.catch(() => alert("An error sorry"));
	}

	async function download_image_for_mobile() {
		try {
			const uri = await FileSystem.downloadAsync(
				today_data.picture_of_the_day.download_url,
				FileSystem.documentDirectory + encodeURIComponent(today_data.picture_of_the_day.title.split(" ").join("_") + "." +
					today_data.picture_of_the_day.download_url.split(".").pop())
			);
			if (uri) {
				const permission = await MediaLibrary.requestPermissionsAsync();
				if (permission.granted) {
					const image = await MediaLibrary.createAssetAsync(uri.uri);
					if (image) {
						let album = await MediaLibrary.getAlbumAsync("Meta Takvim");
						if (album) {
							await MediaLibrary.addAssetsToAlbumAsync([image], album, false);
						} else {
							album = await MediaLibrary.createAlbumAsync("Meta Takvim", image, false);
						}
						const assetResult = await MediaLibrary.getAssetsAsync({
							first: 1,
							album,
							sortBy: MediaLibrary.SortBy.creationTime
						});
						const asset = await assetResult.assets[0];
						if (asset) {
							toast.show({
								duration: 3000,
								placement: "top",
								render: () => {
									return <Alert w="100%" variant="left-accent"
												  colorScheme="success"
												  status="success">
										<HStack space={2} flexShrink={1} alignItems="center">
											<Alert.Icon/>
											<Text color={"coolGray.800"}>
												Resim indirme işlemi tamamlandı ve galerinize kaydedildi.
											</Text>
										</HStack>
									</Alert>;
								}
							});
						}
					}
				}
			}
		} catch (e) {
			toast.show({
				duration: 3000,
				placement: "top",
				render: () => {
					return <Alert w="100%" variant="left-accent"
								  colorScheme="error"
								  status="error">
						<HStack space={2} flexShrink={1} alignItems="center">
							<Alert.Icon/>
							<Text color={"coolGray.800"}>
								Resim indirme işlemi sırasında bir hata oluştu.
							</Text>
						</HStack>
					</Alert>;
				}
			});
		} finally {
			set_is_loading(false);
		}
	}

	return (
		<TouchableOpacity disabled={is_loading} onPress={async () => {
			if (is_web) {
				download_image_for_web(today_data.picture_of_the_day.download_url, today_data.picture_of_the_day.title);
				return;
			}
			set_is_loading(true);
			await download_image_for_mobile();
			return;
		}}>
			<HStack alignItems="center" space={3} bgColor="#fff9" mt={4} p={2} borderRadius={4}>
				<Center width={10}>
					{!!is_loading ? <ActivityIndicator size="small" color="black"/> :
						<Icon as={FontAwesome} name="camera" size={"xl"}/>}
				</Center>
				<Text flex={1} fontSize="md" fontWeight="600">Günün
					Fotoğrafı: {today_data?.picture_of_the_day?.title}
				</Text>
			</HStack>
		</TouchableOpacity>
	);
}
