import {animated, to, useScroll} from "@react-spring/web";
import logo from "../assets/icon.png";
import logo_text from "../assets/icon_text.png";
import React, {useEffect} from "react";
import {is_web} from "../helpers";
import {Box} from "native-base";

export function WebAppBar({is_modal_open}: { is_modal_open: boolean }) {
	const {scrollYProgress} = useScroll({});
	useEffect(() => {
		if (!!is_modal_open && !!is_web) {
			document.body.style.overflow = "hidden";
		} else {
			document.body.style.overflow = "unset";
		}
	}, [is_modal_open]);

	return (
		<div style={{
			position: "sticky",
			zIndex: 1,
			top: 0,
			width: "auto",
			background: "#fff",
			display: "flex",
			alignItems: "center",
			flexDirection: "column"
		}}>
			<Box>
				<animated.img
					src={logo}
					width={to(scrollYProgress, v => v < 0.20 ? 100 - v * 5 * 40 : 60)}
					height={to(scrollYProgress, v => {
						return (v < 0.20 ? 60 - v * 5 * 30 : 30);
					})}
					style={{
						objectFit: "contain",
						marginTop: 10
					}}
				/>
				<animated.span
					style={{
						fontWeight: "300",
						position: "absolute",
						fontSize: to(scrollYProgress, v => v < 0.20 ? 20 - v * 5 * 10 : 10),
						fontStyle: "italic",
						right: 0,
						top: 0
					}}>beta
				</animated.span>
			</Box>
			<animated.img
				src={logo_text}
				width="100"
				height={to(scrollYProgress, v => v < 0.20 ? 30 - v * 5 * 30 : 0)}
				style={{
					objectFit: "contain",
					marginBottom: 10
				}}
			/>

		</div>
	);
}

