import {Box, Text, VStack} from "native-base";
import React from "react";

export function ShortCard(props: {
	author: string,
	title: string,
	text: string,
	bg_color?: string,
}) {
	return (
		<VStack
			backgroundColor={props.bg_color || "#fffe"}
			borderRadius={8}
			mt={4}
			borderWidth={1}
			borderColor={"#f1f1f1"}
		>
			<Box
				p={{
					base: 4,
					sm: 8
				}}
				pt={{
					base: 3,
					sm: 5
				}}>
				<Text fontSize="lg" fontWeight="600">{props.title}</Text>
				<Text
					fontSize="sm"
					lineHeight={17}
					color="gray.500"
				>
					{props.text}
				</Text>
				<Text alignSelf="flex-end" fontSize="md" fontWeight="600">{props.author}</Text>
			</Box>
		</VStack>
	);
}
