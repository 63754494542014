import {StyleSheet} from "react-native";
import React, {useCallback} from "react";
import {extendTheme, NativeBaseProvider, View} from "native-base";
// import {useFonts} from "expo-font";
import * as SplashScreen from "expo-splash-screen";
import MainPage from "./MainPage";
import {StatusBar} from "expo-status-bar";
import {useFonts, WorkSans_100Thin, WorkSans_200ExtraLight, WorkSans_300Light, WorkSans_400Regular, WorkSans_500Medium, WorkSans_600SemiBold, WorkSans_700Bold, WorkSans_800ExtraBold, WorkSans_900Black} from "@expo-google-fonts/work-sans";
import {NavigationContainer} from "@react-navigation/native";
import {createNativeStackNavigator} from "@react-navigation/native-stack";
import Privacy from "./Privacy";

SplashScreen.preventAutoHideAsync().then();
const Stack = createNativeStackNavigator();

const config = {
	screens: {
		Home: "Anasayfa",
		Privacy: "Privacy"
	}
};

const linking = {
	prefixes: ["https://www.metatakvim.com", "metatakvim://",
		"http://localhost:19006/"
	],
	config
};

const theme = extendTheme({
	fontConfig: {
		WorkSans: {
			100: {
				normal: "WorkSans_100Thin"
			},
			200: {
				normal: "WorkSans_200ExtraLight"
			},
			300: {
				normal: "WorkSans_300Light"
			},
			400: {
				normal: "WorkSans_400Regular"
			},
			500: {
				normal: "WorkSans_500Medium"
			},
			600: {
				normal: "WorkSans_600SemiBold"
			},
			700: {
				normal: "WorkSans_700Bold"
			},
			800: {
				normal: "WorkSans_800ExtraBold"
			},
			900: {
				normal: "WorkSans_900Black"
			}
		}
	},

	// Make sure values below matches any of the keys in `fontConfig`
	fonts: {
		heading: "WorkSans",
		body: "WorkSans",
		mono: "WorkSans"
	}
});

export default function App() {
	const [fontsLoaded] = useFonts({
		WorkSans_100Thin,
		WorkSans_200ExtraLight,
		WorkSans_300Light,
		WorkSans_400Regular,
		WorkSans_500Medium,
		WorkSans_600SemiBold,
		WorkSans_700Bold,
		WorkSans_800ExtraBold,
		WorkSans_900Black
	});
	// const [fontsLoaded] = useFonts({
	// 	"Montserrat-Black": require("./assets/fonts/Montserrat-Black.otf"),
	// 	"Montserrat-BlackItalic": require("./assets/fonts/Montserrat-BlackItalic.otf"),
	// 	"Montserrat-Bold": require("./assets/fonts/Montserrat-Bold.otf"),
	// 	"Montserrat-BoldItalic": require("./assets/fonts/Montserrat-BoldItalic.otf"),
	// 	"Montserrat-ExtraBold": require("./assets/fonts/Montserrat-ExtraBold.otf"),
	// 	"Montserrat-ExtraBoldItalic": require("./assets/fonts/Montserrat-ExtraBoldItalic.otf"),
	// 	"Montserrat-ExtraLight": require("./assets/fonts/Montserrat-ExtraLight.otf"),
	// 	"Montserrat-ExtraLightItalic": require("./assets/fonts/Montserrat-ExtraLightItalic.otf"),
	// 	"Montserrat-Italic": require("./assets/fonts/Montserrat-Italic.otf"),
	// 	"Montserrat-Light": require("./assets/fonts/Montserrat-Light.otf"),
	// 	"Montserrat-LightItalic": require("./assets/fonts/Montserrat-LightItalic.otf"),
	// 	"Montserrat-Medium": require("./assets/fonts/Montserrat-Medium.otf"),
	// 	"Montserrat-MediumItalic": require("./assets/fonts/Montserrat-MediumItalic.otf"),
	// 	"Montserrat-Regular": require("./assets/fonts/Montserrat-Regular.otf"),
	// 	"Montserrat-SemiBold": require("./assets/fonts/Montserrat-SemiBold.otf"),
	// 	"Montserrat-SemiBoldItalic": require("./assets/fonts/Montserrat-SemiBoldItalic.otf"),
	// 	"Montserrat-Thin": require("./assets/fonts/Montserrat-Thin.otf"),
	// 	"Montserrat-ThinItalic": require("./assets/fonts/Montserrat-ThinItalic.otf"),
	// 	"WorkSans": require("./assets/fonts/WorkSans-VariableFont_wght.ttf")
	// });

	const onLayoutRootView = useCallback(async () => {
		if (fontsLoaded) {
			await SplashScreen.hideAsync();
		}
	}, [fontsLoaded]);

	console.log("fontsLoaded", fontsLoaded);
	if (!fontsLoaded) {
		return null;
	}

	return (
		<NativeBaseProvider theme={theme}>
			<StatusBar style="auto"/>
			<View flex={1} onLayout={onLayoutRootView}>
				<NavigationContainer linking={linking}>
					<Stack.Navigator initialRouteName="Anasayfa">
						<Stack.Screen
							name={"Anasayfa"}
							component={MainPage}
							options={{
								headerShown: false
							}}
						/>
						<Stack.Screen
							name={"Privacy"}
							component={Privacy}
							options={{
								headerBackTitle: "Ana Sayfa",
								title: "Gizlilik Politikası"
							}}
						/>
					</Stack.Navigator>
				</NavigationContainer>
			</View>
		</NativeBaseProvider>
	);
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
		backgroundColor: "#fff",
		alignItems: "center",
		justifyContent: "center"
	}
});
