import {Animated} from "react-native";
import React, {useEffect, useRef, useState} from "react";
import {Actionsheet, Box, Center, ScrollView, Text, VStack} from "native-base";
import {InfoCardContainer} from "./components/info_card_container";
import HeaderCard from "./components/header_card";
import {SponsoredCard} from "./components/sponsored_card";
import AudioPlayer from "./components/audio_player";
import logo from "./assets/icon.png";
import logo_text from "./assets/icon_text.png";
import {useSafeAreaInsets} from "react-native-safe-area-context";
import {WebAppBar} from "./components/web_app_bar";
import {BASE_API, hp, is_web} from "./helpers";
import {PictureOfTheDayCard} from "./components/picture_of_the_day_card";
import {useNavigation} from "@react-navigation/native";
import {ShortCard} from "./components/short_card";
import AnimatedInterpolation = Animated.AnimatedInterpolation;

export type PictureOfTheDay = {
	bg_url: string,
	download_url: string,
	title: string,
}
type DayContent = {
	type: "article" | "sponsor" | "podcast" | "short",
	caption: string,
	color: string,
	title: string,
	text: string,
	url: string,
	length: string,
	header_image_url: string,
	author: string
};

export type Day = {
	_id: string,
	day: number,
	month: number,
	year: number,
	message_of_the_day: string,
	picture_of_the_day: PictureOfTheDay,
	content: DayContent[]
}

export default function MainPage({onLayoutRootView}) {
	const navigation = useNavigation();
	const animated_value = useRef(new Animated.Value(0)).current;
	const insets = useSafeAreaInsets();
	const [action_sheet_data, set_action_sheet_data] = useState<DayContent>();
	const [is_loading, set_is_loading] = useState(true);
	const [
		today_data,
		set_today_data
	] = useState<Day>();

	useEffect(() => {
		fetch(`${BASE_API}/api/get-today`)
			.then(response => {
				return response.json();
			})
			.then(data => {
				set_today_data(data);
			})
			.finally(() => {
				set_is_loading(false);
			});
	}, []);

	if (is_loading) {
		return <Center onLayout={onLayoutRootView} bgColor="white" flex={1}>
			<Text>Yükleniyor...</Text>
		</Center>;
	}
	if (!today_data || !today_data.content) {
		return <Center onLayout={onLayoutRootView} bgColor="white" flex={1}>
			<Text>Veri bulunamadı!</Text>
		</Center>;
	}
	const animated_height_logo = animated_value.interpolate({
		inputRange: [0, 100],
		outputRange: [50, 35],
		extrapolate: "clamp"
	});
	const animated_width_logo = animated_value.interpolate({
		inputRange: [0, 100],
		outputRange: [100, 80],
		extrapolate: "clamp"
	});
	const animated_height_logo_text = animated_value.interpolate({
		inputRange: [0, 100],
		outputRange: [25, 0],
		extrapolate: "clamp"
	});
	const animated_fontsize_beta_text = animated_value.interpolate({
		inputRange: [0, 100],
		outputRange: [12, 8],
		extrapolate: "clamp"
	});

	return (
		<Box flex={1} bgColor="#f5f5f5">
			{
				is_web ? <WebAppBar is_modal_open={!!action_sheet_data}/> :
					<VStack py={2} safeAreaTop justifyContent="center" alignItems="center" background="#fff">
						<Box>
							<Animated.Image source={logo}
											style={{
												height: animated_height_logo as AnimatedInterpolation<number>,
												width: animated_width_logo,
												resizeMode: "contain"
											}}/>
							<Animated.Text
								style={{
									fontWeight: "300",
									position: "absolute",
									fontSize: animated_fontsize_beta_text,
									fontStyle: "italic",
									right: 0,
									top: -10
								}}>beta</Animated.Text>
						</Box>
						<Animated.Image source={logo_text}
										style={{
											height: animated_height_logo_text as AnimatedInterpolation<number>,
											width: 500,
											resizeMode: "contain"
										}}/>

					</VStack>
			}

			<ScrollView
				px={{
					base: 4,
					sm: 10
				}}
				contentContainerStyle={{
					paddingTop: 10,
					paddingBottom: is_web ? 10 : (insets.bottom > 0 ? insets.bottom : 10),
					maxWidth: 768,
					alignSelf: is_web ? "center" : undefined
				}}
				scrollEventThrottle={16}
				onScroll={Animated.event(
					[{nativeEvent: {contentOffset: {y: animated_value}}}],
					{useNativeDriver: false}
				)}
			>
				<TransitionContainer>
					<HeaderCard {...today_data} />
				</TransitionContainer>

				{
					today_data.content.map((item, index) => {
						if (item.type === "article") {
							return (
								<TransitionContainer
									key={index}
									delay={(index + 1) * 100}>
									<InfoCardContainer
										title={item.title}
										content={item.text}
										bg_color={item.color}
										caption={item.caption}
										header_image_url={item.header_image_url}
										on_continue_clicked={() => {
											set_action_sheet_data(item);
										}}
									/>
								</TransitionContainer>
							);
						}
						if (item.type === "sponsor") {
							return <TransitionContainer
								key={index}
								delay={(index + 1) * 100}>
								<SponsoredCard
									caption={item.caption}
									content={item.text}
									url={item.url}
								/>
							</TransitionContainer>;
						}
						if (item.type === "podcast") {
							return <TransitionContainer
								key={index}
								delay={(index + 1) * 100}>
								<InfoCardContainer
									caption={item.caption}
									title={item.title}
									header_image_url={item.header_image_url}
									is_favorite={false}
									on_continue_clicked={() => {
										set_action_sheet_data(item);
									}}
								>
									<AudioPlayer mp3={item.url} duration={item.length}/>
								</InfoCardContainer>
							</TransitionContainer>;
						}
						if (item.type === "short") {
							return <TransitionContainer
								key={index}
								delay={(index + 1) * 100}>
								<ShortCard author={item.author} text={item.text} title={item.title}
										   bg_color={item.color}
								/>
							</TransitionContainer>;
						}
					})
				}
				<TransitionContainer>
					<PictureOfTheDayCard today_data={today_data}/>
				</TransitionContainer>
				<Text mt={3} alignSelf="center"
					  onPress={() => navigation.navigate("Privacy")}>Privacy Policy</Text>
			</ScrollView>
			<Actionsheet
				isOpen={!!action_sheet_data}
				onClose={() => set_action_sheet_data(undefined)}>
				<Actionsheet.Content>
					<VStack space="2"
							maxHeight={hp(80)}
							minHeight={hp(30)}
							borderRadius={8}
							mt={4}
					>
						<VStack px={4}>
							<Text fontSize={["2xs", "2xs", "xs"]}
								  fontWeight="600">{action_sheet_data?.caption}</Text>
							<Text fontSize="md" fontWeight="600">{action_sheet_data?.title}</Text>
						</VStack>

						<ScrollView contentContainerStyle={{
							padding: 16
						}}>
							<Text
								fontSize="sm"
								ellipsizeMode={"tail"}
								fontWeight="300">
								{action_sheet_data?.text}

							</Text>
						</ScrollView>
					</VStack>
				</Actionsheet.Content>
			</Actionsheet>
		</Box>
	);
}

function TransitionContainer({
								 children,
								 delay
							 }: {
	children: React.ReactNode, delay?: number,
}) {
	const animation_value = useRef(new Animated.Value(0)).current;

	useEffect(() => {
		Animated.timing(animation_value, {
			toValue: 1,
			duration: 500,
			delay: delay || 0,
			useNativeDriver: true
		}).start();
	}, []);
	return (
		<Animated.View style={{opacity: animation_value}}>
			{children}
		</Animated.View>
	);
}
