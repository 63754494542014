import {HStack, Icon, IconButton, Text, VStack} from "native-base";
import {MaterialCommunityIcons} from "@expo/vector-icons";
import React from "react";

export function SponsoredCard(props: {
	caption: string,
	content?: string,
	bg_color?: string,
	url?: string,
}) {
	return (
		<HStack space="2" backgroundColor={props.bg_color || "#DFA4BFDD"}
				borderRadius={8}
				mt={4}
				p={{
					base: 4,
					sm: 8
				}}>
			<VStack flex={1}>
				<Text fontSize={["2xs", "2xs", "xs"]} fontWeight="600" color="blue.700">{props.caption}</Text>
				<Text fontSize="xs" fontWeight="600">{props.content}</Text>
			</VStack>
			<IconButton
				onPress={() => {
					console.log("pressed", props.url);
					if (props.url) {
						window.open(props.url, "_blank");
					}
				}}
				icon={<Icon as={MaterialCommunityIcons}
							name="gesture-tap"
							size="3xl"
							color={"warmGray.700"}
				/>}/>

		</HStack>
	);
}
