import React, {useEffect, useMemo} from "react";
import {Audio, AVPlaybackStatus} from "expo-av";
import {Box, Center, HStack, Icon, IconButton, Slider, Text, VStack} from "native-base";
import {StyleSheet} from "react-native";
import {Sound} from "expo-av/build/Audio/Sound";
import {Ionicons, MaterialIcons} from "@expo/vector-icons";

function string_to_milliseconds(duration: string) {
	const [hour, min, sec] = duration.split(":");
	return (+hour * 60 * 60 + +min * 60 + +sec) * 1000;
}

function milliseconds_to_string(duration: number) {
	const hours = Math.floor(duration / 1000 / 60 / 60);
	const minutes = Math.floor(duration / 1000 / 60) % 60;
	const seconds = Math.floor(duration / 1000) % 60;
	return `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
}

function AudioPlayer({
						 mp3,
						 duration
					 }: { mp3: string, duration: string }) {
	const [sound, set_sound] = React.useState<Sound>();
	const [slider_info, set_slider_info] = React.useState({
		current_position: 0,
		is_playing: false
	});

	useEffect(() => {
		Audio.Sound.createAsync({uri: mp3}, {},
			(status: AVPlaybackStatus) => {
				if (status.isLoaded) {
					set_slider_info({
						current_position: status.positionMillis,
						is_playing: status.isPlaying
					});
				}
			}, true).then((sound) => {
			set_sound(sound.sound);
		});
	}, []);

	React.useEffect(() => {
		return sound
			? () => {
				sound.unloadAsync().then();
			}
			: undefined;
	}, [sound]);

	const duration_milliseconds = useMemo(() => string_to_milliseconds(duration), [duration]);

	const play_sound = async () => {
		try {
			await sound.playFromPositionAsync(slider_info.current_position || 0);
		} catch (e) {
			console.log("error2", e);
		}
	};

	async function back_10_seconds() {
		try {
			await sound.setPositionAsync(slider_info.current_position < 10000 ? 0 : slider_info.current_position - 10000);
		} catch (e) {
			console.log("error", e);
		}
	}

	async function forward_10_seconds() {
		try {
			await sound.setPositionAsync((slider_info.current_position + 10000) > duration_milliseconds ?
				duration_milliseconds : (slider_info.current_position + 10000));
		} catch (e) {
			console.log("error", e);
		}
	}

	async function stop_sound() {
		try {
			sound.stopAsync().then(r => {
				set_slider_info({
					current_position: 0,
					is_playing: false
				});
			});
		} catch (e) {
			console.log("error", JSON.stringify(e, null, 2));
		}
	}

	return (
		<VStack style={styles.container}>
			<HStack flex={1} space={4} alignItems="center">
				<IconButton icon={<Icon as={MaterialIcons} size="3xl" name="replay-10"/>}
							onPress={back_10_seconds}/>
				<Box flex={1}>
					<Slider
						defaultValue={0}
						value={slider_info.current_position}
						minValue={0}
						width={"100%"}
						maxValue={duration_milliseconds}
						step={1}>
						<Slider.Track>
							<Slider.FilledTrack/>
						</Slider.Track>
						<Slider.Thumb/>
					</Slider>
				</Box>
				<IconButton icon={<Icon as={MaterialIcons} size="3xl" name="forward-10"/>}
							onPress={forward_10_seconds}/>
			</HStack>
			<Center>
				<HStack space={3}>
					<Text>{milliseconds_to_string(slider_info.current_position)}</Text>
					<Text> -</Text>
					<Text>{duration}</Text>
				</HStack>
				<HStack space={2}>
					<IconButton icon={<Icon as={MaterialIcons} size="5xl"
											name={slider_info.is_playing ? "pause-circle-outline" : "play-circle-outline"}/>}
								onPress={() => slider_info.is_playing ? sound.pauseAsync() : play_sound()}/>
					<IconButton
						onPress={stop_sound}
						icon={<Icon as={Ionicons} size="5xl"
									name={"ios-stop-circle-outline"}/>}/>
				</HStack>
			</Center>
		</VStack>
	);
}

const styles = StyleSheet.create({
	container: {
		flex: 1
	}
});

export default AudioPlayer;
