import {Dimensions, PixelRatio, Platform} from "react-native";

const {
	width,
	height
} = Dimensions.get("window");

const widthPercentageToDP = (widthPercent) => {
	const elemWidth = parseFloat(widthPercent);
	return PixelRatio.roundToNearestPixel((width * elemWidth) / 100);
};

const heightPercentageToDP = (heightPercent) => {
	const elemHeight = parseFloat(heightPercent);
	return PixelRatio.roundToNearestPixel((height * elemHeight) / 100);
};

const FontSizeDict = {
	font1: width / 360
};

export const is_web = Platform.OS === "web";

export const BASE_API = __DEV__ ? "http://localhost:7071" : "https://meta-takvim-backend.azurewebsites.net/";

export {widthPercentageToDP as wp, heightPercentageToDP as hp, FontSizeDict};
