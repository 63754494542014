import {Box, Center, HStack, Icon, Skeleton, Text, VStack} from "native-base";
import {Feather} from "@expo/vector-icons";
import React, {memo, useEffect, useState} from "react";
import {ImageBackground} from "react-native";
import {PictureOfTheDay} from "../MainPage";
import {BASE_API} from "../helpers";

type Location = {
	geoplugin_city: string,
	geoplugin_latitude: number,
	geoplugin_longitude: number,
}

type SunriseSunset = {
	sunrise: string,
	sunset: string,
	solar_noon: string,

	civil_twilight_begin: string,
	civil_twilight_end: string,

	nautical_twilight_begin: string,
	nautical_twilight_end: string,

	astronomical_twilight_begin: string,
	astronomical_twilight_end: string,

}

function get_day_name_from_number(year: number, month: number, date: number) {
	const day_names = ["Pazar", "Pazartesi", "Salı", "Çarşamba", "Perşembe", "Cuma", "Cumartesi"];
	return day_names[new Date(year, month - 1, date).getDay()];
}

function get_hour_and_minute(date_string: string) {
	const hour = new Date(date_string).getHours();
	const minute = new Date(date_string).getMinutes();
	if (hour < 10 && minute < 10) {
		return `0${hour}:0${minute}`;
	}
	if (hour < 10) {
		return `0${hour}:${minute}`;
	}
	if (minute < 10) {
		return `${hour}:0${minute}`;
	}
	return `${hour}:${minute}`;
}

export default function HeaderCard({
									   day,
									   month,
									   year,
									   picture_of_the_day,
									   message_of_the_day
								   }: {
	day: number,
	month: number,
	year: number,
	picture_of_the_day: PictureOfTheDay,
	message_of_the_day: string,
}) {
	const day_name = get_day_name_from_number(year, month, day).toLocaleUpperCase();
	const letter_spacing = 1 - ((day_name.length - 4) * 0.4); // -1 1 arasına çekiyoruz

	const day_number_fontsize_mobile = day.toString().length === 1 ? 80 : 72;
	const day_number_fontsize_web = day.toString().length === 1 ? 100 : 80;

	const day_name_fontsize_mobile = 36 - ((day_name.length - 4) * (12 / 5)); // 36 - 24 arası çekiyoruz
	const day_name_fontsize_web = 96 - ((day_name.length - 4) * (36 / 5)); // 96-60 arası çekiyoruz

	const margin_top = 48 - ((day_name.length - 4) * (18 / 5)); // 48 - 30 arası çekiyoruz

	return (
		<VStack backgroundColor="#fffe"
				borderRadius={4}
				overflow="hidden"
		>
			<Box flex={1} bgColor="#FF914D" p={2}>
				<Center>
					<CalendarInfo day={day} month={month}/>
				</Center>
				{/*<Text color="white" fontSize="2xl" fontWeight="bold"*/}
				{/*	  mt={3}>{get_month_name_from_number(month).toLocaleUpperCase()} {year}</Text>*/}
			</Box>
			<ImageBackground source={{uri: picture_of_the_day?.bg_url}}>
				<VStack
					space={2}
					pb={{
						base: 4,
						sm: 8
					}}
					px={{
						base: 4,
						sm: 8
					}}
				>
					<Box mt={2} mb={8} alignSelf="flex-start">
						<Center>
							<Text
								fontSize={[day_number_fontsize_mobile, day_number_fontsize_mobile, day_number_fontsize_web]}
								color={"#fffd"}
								fontWeight="900"
							>{day}</Text>
							<Text
								fontSize={[day_name_fontsize_mobile, day_name_fontsize_mobile, day_name_fontsize_web]}
								color={"#fffd"}
								letterSpacing={letter_spacing}
								fontWeight="700"
								mt={[-8, -8, -margin_top + "px"]}
							>{day_name}
							</Text>
						</Center>
					</Box>
					<Center>
						<Box px={2} backgroundColor={"#421F03aa"}>
							<Text fontWeight="400"
								  color="white"
								  fontSize={{
									  base: "md"
								  }}>{message_of_the_day}</Text>
						</Box>

					</Center>
				</VStack>
			</ImageBackground>

		</VStack>

	);
}

const CalendarInfo = memo(({
							   day,
							   month
						   }: { day: number, month: number }) => {
	const [is_loading, set_is_loading] = useState(true);
	const [location, set_location] = useState<Location>();
	const [sunrise_sunset_data, set_sunrise_sunset_data] = useState<SunriseSunset>();

	useEffect(() => {
		fetch("https://api.ipify.org?format=json").then(response => response.json()).then(ip_address => {
			fetch(`${BASE_API}/api/get-today?action=get_location_info&ip_address=${ip_address.ip}`)
				.then(response => {
					return response.json();
				})
				.then(data => {
					set_location(data);
				})
				.finally(() => {
					set_is_loading(false);
				});
		});

	}, []);

	useEffect(() => {
		if (!!location && location.geoplugin_latitude && location.geoplugin_longitude) {
			fetch(`https://api.sunrise-sunset.org/json?lat=${location.geoplugin_latitude}&lng=${location.geoplugin_longitude}&date=${new Date().getFullYear()}-${month}-${day}&formatted=0`)
				.then(response => response.json())
				.then(data => set_sunrise_sunset_data(data.results))
				.finally(() => {
					set_is_loading(false);
				});
		}
	}, [location?.geoplugin_longitude, location?.geoplugin_longitude]);

	if (!sunrise_sunset_data) {
		return <Box height={25}/>;
	}

	return (
		<HStack space={[6]} height={28} alignItems="center">
			<Text fontSize="xs" color="white">
				{is_loading ? <Skeleton.Text px="4"/> : location?.geoplugin_city}
			</Text>
			<HStack space={2} alignItems="center">
				<Icon as={Feather} name="sunrise" size="md" color="white"/>
				<Text fontSize="xs"
					  color="white"
				>
					{is_loading ? "" : get_hour_and_minute(sunrise_sunset_data.sunrise)}
				</Text>
			</HStack>
			<HStack space={2} alignItems="center">
				<Icon as={Feather} name="sunset" size="md" color="white"/>
				<Text fontSize="xs" color="white">
					{is_loading ? <Skeleton.Text px="4"/> : get_hour_and_minute(sunrise_sunset_data.sunset)}
				</Text>
			</HStack>
			<HStack space={2} alignItems="center">
				<Icon as={Feather} name="moon" size="md" color="white"/>
				{/*<Text fontSize="sm" color="white" fontWeight="300">*/}
				{/*	{is_loading ? <Skeleton.Text*/}
				{/*		px="4"/> : get_hour_and_minute(sunrise_sunset_data.astronomical_twilight_end)}*/}
				{/*</Text>*/}
			</HStack>
		</HStack>
	);
}, (prev, next) => {
	return prev.day === next.day && prev.month === next.month;
});
