import {Box, HStack, Image, Text, VStack} from "native-base";
import React, {useState} from "react";
import {is_web} from "../helpers";

export function InfoCardContainer(props: {
	caption: string,
	title: string,
	is_favorite?: boolean,
	content?: string,
	bg_color?: string,
	header_image_url?: string,
	on_continue_clicked?: () => void,
	children?: React.ReactNode,
}) {
	const [show_continue_button, set_show_continue_button] = useState(true);

	function handle_layout(event) {
		console.log("width", event.nativeEvent.layout.width);
	}

	return (
		<VStack
			backgroundColor="#fffe"
			borderRadius={8}
			mt={4}
			borderWidth={1}
			borderColor={"#f1f1f1"}
		>
			<Box>
				<Image
					alt={props.title}
					width="100%"
					height={24}
					borderTopRadius={8}
					source={{uri: props.header_image_url}}>
				</Image>
				<HStack bgColor={"#4D82FF"}
						position="absolute"
						left={0}
						bottom={0}
						height={8}
						alignItems="center"
						flexDirection="row-reverse"
				>
					<Box bgColor="#4D82FF"
						 width={32 / Math.sqrt(2)}
						 height={32 / Math.sqrt(2)}
						 style={{
							 zIndex: -1,
							 transform: [
								 {
									 translateX: 16 / Math.sqrt(2)
								 },
								 {
									 rotate: "45deg"
								 }
							 ]
						 }}
					></Box>
					<Text color="white" fontSize={["2xs", "2xs", "xs"]}
						  fontWeight="600"
						  px={2}
					>{props.caption}</Text>

				</HStack>
			</Box>
			<Box onLayout={handle_layout}
				 p={{
					 base: 4,
					 sm: 8
				 }}
				 pt={{
					 base: 3,
					 sm: 5
				 }}>
				<HStack>
					<VStack flex={1} space={3}>
						<Text fontSize="lg" fontWeight="600">{props.title}</Text>
					</VStack>
				</HStack>
				{!!props.content && <Box>
                    <Text
                        fontSize="sm"
                        lineHeight={17}
                        color="gray.500"
                    >
						{props.content.slice(0, is_web ? 150 : 100).concat(" ...")}
                    </Text>
					{!!show_continue_button && <Text alignSelf="flex-end" color="blue.700" fontSize="sm"
                                                     onPress={!!props.on_continue_clicked && props.on_continue_clicked}>devamı</Text>}
                </Box>}
				{
					!!props.children && props.children
				}
			</Box>
		</VStack>
	);
}
